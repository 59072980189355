$tr-time: .3s;

$main-clr: #b70404;//#C80000;
$main-clr-light: #FF3232;
$main-clr-hover: $main-clr;

$bg: #373737;
$bg-dark: #272727;

$alt-clr: #282828;
$text-clr: #fff;