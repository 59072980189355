.theme-form{
    position: relative;
}


.theme-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #272727e2;
    z-index: 5;
    border-radius: 20px;
    img{
        width: 20%;
        height: 50%;
    }
}

.theme-input {
    $this: &;
    display: block;
    position: relative;
    border: 1px solid #C9CED8;
    background-color: #fff;
    padding: 20px 15px 0;
    width: 100%;
    transition-property: border-color;
    transition-duration: $tr-time;
    cursor: text;
    @include _768{
        height: 50px;
    }

    &:not(:last-child) {
        margin: 0 0 14px;
    }
    &.error {
        border-color: #ff2828;

        #{$this}__placeholder {
            color: #ff2828;
        }
    }
    &__field {
        border: none;
        font-size: 16px;
        line-height: 24px;
        color: $text-clr;
        width: 100%;
        background-color: transparent;
        position: relative;
        z-index: 1;

        &::placeholder {
            opacity: 0;
        }

        &:disabled{
            opacity: .6;
        }

        &:focus+span,
        &:not(:placeholder-shown)+span {
            top: 6px;
            font-size: 12px;
            line-height: 14px;
        }
    }

    &__placeholder {
        position: absolute;
        display: block;
        font-size: 15px;
        line-height: 18px;
        color: #878787;
        left: 15px;
        top: calc(50% - 10px);
        transition-property: font-size, top, color;
        transition-duration: $tr-time;
        @include _576{
            font-size: 14px;
            line-height: 22px;
        }
    }
    
    &__password-toggle{
        width: 24px;
        height: 24px;
        position: absolute;
        right: 15px;
        top: calc(50% - 12px);
        fill: #999999;
        cursor: pointer;
        transition: fill $tr-time;
        z-index: 3;
        &.active{
            fill: $main-clr-hover;
        }
    }
}

.theme-textarea{
    &__field{
        resize: none;
        height: 150px;
    }
    &__placeholder{
        top: 15px;
    }
}

.theme-toggle{
    $this: &;
    user-select: none;
    cursor: pointer;
    display: grid;
    grid-template-columns: 65px 1fr;
    align-items: center;
    &:not(:last-child){
        margin: 0 0 10px;
    }
    &:hover{
        #{$this}__name{
            color: $main-clr-hover;
        }
    }
    &__input{
        display: none;
        &:checked + div{
            background-color: #282828;
            border-color: #282828;
            &::before{
                right: 3px;
                border-color: #282828;
            }
        }
    }
    &__fake{
        position: relative;
        width: 50px;
        height: 28px;
        background-color: #E3E3E3;
        border: 1px solid #D0D0D0;
        transition: $tr-time;
        &::before{
            content: '';
            display: block;
            position: absolute;
            width: 50%;
            height: calc(100% - 4px);
            background-color: #FFFFFF;
            border: 1px solid #D0D0D0;
            top: 2px;
            right: calc(100% - 50% - 3px);
            transition: $tr-time;
        }
    }
    &__name{
        font-size: 14px;
        line-height: 18px;
        color: $text-clr;
        transition: color $tr-time;
    }
}

.choice-block{
    $this: &;
    cursor: pointer;
    user-select: none;
    &__field{
        display: none;
        &:checked{
            + #{$this}__front{
                border-color: #D0D0D0;
                background-color: transparent;

                #{$this}__fake{
                    background-color: #282828;
                    border-color: #282828;
                    svg{
                        fill: #FFFFFF;
                    }
                }
            }
        }
    }
    &__front{
        background-color: #F8F8F8;
        border: 1px solid transparent;
        padding: 20px 18px;
        transition: $tr-time;
        width: 100%;
        height: 100%;
    }
    &__fake{
        width: 22px;
        height: 22px;
        background-color: #FFFFFF;
        border: 1px solid #D0D0D0;
        border-radius: 50%;
        transition: 
            background-color $tr-time,
            border-color $tr-time;
        svg{
            width: 12px;
            height: 9px;
            fill: transparent;
            transition: fill $tr-time;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #333333;
        margin: 12px 0 0;
    }
    &__desc{
        font-size: 14px;
        line-height: 18px;
        color: #7D7D7D;
        margin: 8px 0 0;
    }
    &__link{
        padding: 10px 0 0;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        &:hover{
            color: $main-clr-hover;
        }
    }
}
.theme-checkbox {
    cursor: pointer;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    user-select: none;
    &__field{
        display: none;
        &:checked{
            + div{
                background-color: $alt-clr;
                border-color: $alt-clr;
                svg{
                    opacity: 1;
                }
            }
        }
    }
    &__fake{
        width: 18px;
        height: 18px;
        background: #FFFFFF;
        border: 1px solid #D0D0D0;
        transition: $tr-time;
        svg{
            width: 12px;
            height: 9px;
            fill: #fff;
            opacity: 0;
            transition: opacity $tr-time;
        }
    }
    &__name{
        font-size: 14px;
        line-height: 18px;
        color: #282828;
    }
}

.theme-submit{
    width: 100%;
    height: 45px;
}

.g-recaptcha{
    overflow: hidden;
    &:not(:last-child){
        margin: 0 0 14px;
    }
}

.theme-policy{
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    margin: 12px 0 0;
    a{
        color: $main-clr-hover;
    }
}

.noUi-horizontal{
    height: 4px;
}
.noUi-target{
    background: #282828;
    border: none;
    box-shadow: none;
    margin: 0 10px;
}
.noUi-connect{
    background-color: #999;
}
.noUi-horizontal .noUi-handle{
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: none;
    background: #999;
    border-radius: 50%;
    box-shadow: none;
    right: -7px;
    top: -6px;
    &:after,
    &:before{
        display: none;
    }
}
.noUi-touch-area{
    display: none;
}

.radio-block{
    cursor: pointer;
    &:not(:last-child){
        margin-right: 10px;
    }
    &__field{
        display: none;
        &:checked{
            + span{
                border-color: $main-clr-hover;
            }
        }
    }
    &__name{
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #282828;
        background: #FFFFFF;
        border: 1px solid #D0D0D0;
        padding: 5px 15px;
        transition: border-color $tr-time;
        &:hover{
            border-color: #282828;
        }
    }
}

.theme-select{
    position: relative;
    &__list{
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: calc(100% + 15px);
        left: -1px;
        min-width: calc(100% + 2px);
        border: 1px solid #c9ced8;
        background-color: #fff;
        border-radius: 0 0 5px 5px;
        box-shadow: 4px 6px 8px 0px rgba(35, 60, 80, 0.2);
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        transition: $tr-time;
        z-index: 3;
        &.opened{
            visibility: visible;
            opacity: 1;
            top: calc(100% + 5px);
        }
        label{
            cursor: pointer;
            padding: 3px 10px;
            transition: background-color $tr-time;
            &:hover{
                background-color: #eaeaea;
            }
            input{
                display: none;
            }
            span{
                font-size: 16px;
                line-height: 24px;
                color: #282828;
                white-space: nowrap;
            }
        }
    }
}