.container{
	width: 1755px;
	margin: 0 auto;
	@include _1920{
		width: cfl(1170, 1755);
	}
}

.container--important{
	width: 1755px !important;
	margin: 0 auto !important;
	@include _1920{
		width: cfl(1170, 1755) !important;
	}
}

#page-container{
	padding: 0 0 105px;
	@include _1920{
		padding: 0 0 cfl(70, 105);
	}
}

em{
	font-style: normal;
	color: $main-clr-hover;
}

.loading{
	// opacity: .4;
	filter: blur(3px);
	pointer-events: none;
	user-select: none;
}

.btn{
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;

	&--icon{
		background-color: transparent;
		transition: fill $tr-time;
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
		svg{
			width: 25px;
			height: 20px;
			fill: #B6B6B6;
		}
	}

	&--filled{
		transition: background-color $tr-time;
		border: 2px solid $main-clr;
		border-radius: 41px;
		span{
			text-transform: uppercase;
			font-weight: 700;
			font-size: 21px;
			line-height: 34px;
			color: $text-clr;
			@include _1920{
				font-size: cfl(14, 21);
				line-height: cfl(23,34);
			}
			@include _576{
				font-size: cflm(12, 14);
				line-height: cflm(16,18);
			}
		}
		
		&:hover{
			background-color: $main-clr-hover;
		}
	}

	&--reverse{
		background-color: #fff;
		border: 1px solid $main-clr-hover;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $alt-clr;
			transition: color $tr-time;
		}
		&:hover{
			span{
				color: $main-clr-hover;
			}
		}
	}

	&--black{
		background-color: $alt-clr;
		transition: background-color $tr-time;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: #fff;
		}
		&:hover{
			background: #343434;
		}
	}

	&--border{
		&:hover{
			span{
				color: #fff!important;
			}
		}
		span{
			font-weight: 600;
			font-size: 21px;
			line-height: 26.25px;
			color: #fff;
			text-transform: uppercase;
			transition: color .3s ease;
			&:last-child{
				color: #ADACAC; 
			}
			@include _1920{
				font-size: cfl(14, 21);
				line-height: cfl(17.50, 26.25);
			}
			@include _992{
				font-size: 14px;
				line-height: 17.5px;
			}
			&:first-child{
				font-weight: 100;
				font-size: 59px;
				margin-right: 24px;
				@include _1920{
					font-size: cfl(39.33, 59);
					margin-right: cfl(16, 24);
				}
				@include _992{
					font-size: 40px;
				}
			}
		}
		svg{
			fill: #fff;
			width: 20px;
			height: 20px;
			@include _1920{
				width: cfl(13.33, 20);
				height: cfl(13.33, 20);
			}
		}
	}

	&--gray{
		background-color: #FFFFFF;
		border: 1px solid #D0D0D0;
		transition: border-color $tr-time;
		span{
			font-size: 15px;
			line-height: 18px;
			color: #282828;
			@include _1920{
				font-size: cfl(10, 15);
				line-height: cfl(12, 18);
			}
			@include _992{    
				font-size: 14px;
				line-height: 18px;
				margin: 8px 0;
			}
		}
		&:hover{
			border-color: $main-clr-hover;
		}
	}

	&--poly{
		clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
		background-color: #282828;
		font-weight: 500 !important;
		font-size: 12px !important;
		line-height: 14px !important;
		color: #FFFFFF !important;
		width: 22px;
		height: 20px;
		transform: scale(1);
		transition: transform $tr-time;
		&:empty{
			transform: scale(0);
		}
	}

	&--swal{
		padding: 15px 20px;
		margin: 0 5px;
	}
}
.banner{
    background-repeat: no-repeat;
    background-size: cover;
    height: 778px;
    display: flex;
    flex-direction: column;
	padding-bottom: 24px;
	@include _1920{
		height: cfl(518.6, 778);
		padding-bottom: cfl(16,24);
	}
	@include _576{
		height: cflm(200, 250);
	}
    &__title{
		text-align: center;
        font-size: 48px;
        line-height: 57.6px;
        margin-top: 13.75%;
        text-transform: uppercase;
		z-index: 1;
		@include _1920{
			font-size: cfl(32, 48);
			line-height: cfl(38.4,57.6);
		}
		@include _992{
			font-size: 30px;
			line-height: 36px;
		}
		@include _576{
			font-size: cflm(22,24);
			line-height: cflm(24,26);
			margin-top: 8%;
		}

    }
    &__description{
		z-index: 1;
		text-align: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 29.26px;
        margin-top: 3.1%;
		@include _1920{
			font-size: cfl(16,24);
			line-height: cfl(19.05,29.26);
		}
		@include _992{
			font-size: 15px;
			line-height: 18px;
		}
		@include _576{
			width: 80%;
			margin: 10% auto;
			font-size: cflm(12,14);
			line-height: cflm(14,16);
		}
    }
    &__arrow{
		z-index: 1;
		text-align: center;
        margin-top: auto;

        svg{
            width: 48px;
            height: 23px;
            fill: transparent;
            transition: all .3s ease;
            stroke: #fff;
            cursor: pointer;
            @include _1920{
                width: cfl(32, 48);
				height: cfl(15.3, 23);
            }
			@include _576{
				width: cflm(20, 25);
				height: cflm(20, 25);
			}
            &:hover{
                stroke: $main-clr;
            }
        }
    }
}

.d-flex-row{
	display: flex;
	flex-direction: row;
}

.subscribe{
    margin-top: 87px;
    padding-bottom: 89px;
    border-bottom: 1.5px solid #4B4B4B;
	
	@include _1920{
		margin-top: cfl(58, 87);
		padding-bottom: cfl(59, 89);
	}
	@include _992{
		margin-top: 80px;
		padding-bottom: 35px;
	}
	@include _576{
		margin-top: cflm(40,60);
	}
    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
		&.hide{
			display: none;
		}
    }
    &__title{
        text-transform: uppercase;
        font-size: 40.5px;
        line-height: 63px;
        margin-bottom: 25px;
		@include _1920{
			font-size: cfl(27, 40.5);
			line-height: cfl(42,63);
			margin-bottom: cfl(17.25, 25);
		}
		@include _992{
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 17px;
		}
		@include _576{
			text-align: center;
			font-size: 18px;
			line-height: 23px;
		}
    }
    &__form{
		position: relative;
        width: 415px;
		@include _1920{
			width: cfl(277, 415);
		}
		@include _992{
			width: 300px;
		}
        label{
            display: flex;
            background-color: #272727;
            border-radius: 9px;
            height: 89px;
            padding: 5px 36px 0;
			@include _1920{
				border-radius: cfl(6, 9);
				height: cfl(60, 89);
				padding: cfl(3.3, 5) cfl(24,36) 0;
			}
			@include _576{
				height: cflm(42,47);
			}
            input{
                background: transparent;
                border: none;
                font-size: 22.5px;
                line-height: 27.43px;
                color: #fff;
                width: 100%;
				@include _1920{
					font-size: cfl(15, 22.5);
					line-height: cfl(18.28, 27.43);
				}
				@include _992{
					font-size: 15px;
					line-height: 18px;
				}

                &::placeholder{
                    font-size: 22.5px;
                    line-height: 27.43px;
					@include _1920{
						font-size: cfl(15, 22.5);
						line-height: cfl(18.28, 27.43);
					}
					@include _992{
						font-size: 15px;
						line-height: 18px;
					}
                    
                    color: rgba(255,255,255,0.5);
                }
            }
            button{
                display: flex;
                align-items: center;
                background-color: transparent;
                border: none;
                width: 32px;
				cursor: pointer;
				transition: fill .3s ease;
				@include _1920{
					width: cfl(21,32);
				}
				@include _576{
					width: 20px;
				}
                svg{
                    width: 12px;
                    height: 21px;
                    fill: #fff;
					@include _576{
						height: 14px;
					}
                }
				&:hover{
					svg{
						fill: $main-clr;
						@include _992{
							fill: #fff;
						}
					}
				}
            }
        }
    }
}

.slide-card{
	height: 698px;
	border-radius: 21px;
	overflow: hidden;
	position: relative;
	@include _1920{
		height: cfl(465.3, 698);
		border-radius: cfl(14,21);
	}
	@include _576{
		height: cfl(200, 250);
	}
	&:hover{
		.slide-card__bg{
			transform: translateY(-125px);
			@include _1920{
				transform: translateY(cfl(-77,-125));
			}
		}
		.slide-card__wrapper{
			border-radius: 21px;
			@include _1920{
				border-radius: cfl(14,21);
			}
		} 
	}
	&__wrapper{
		height: 100%;
		border-radius: 24px;
		background-color: $main-clr;
		transition: all .3s ease;
		@include _1920{
			border-radius: cfl(16, 24);
		}
	}
	&__bg{
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		transition: all .3s ease;
		@include _576{
			background-position: center;
		}
	}
	&__link{
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-top: auto;
		align-items: center;
		padding: 0 36px;
		margin-top: -100px;
		@include _1920{
			padding: 0 cfl(24,36);
			margin-top: cfl(-66, -100);
		}
		@include _576{
			margin-top: -32px;
		}
		span{
			transition: color .3s ease;
			font-size: 25.5px;
			line-height: 36px;
			font-weight: 500;
			@include _1920{
				font-size: calcKostyl(17,25.5,1);
				line-height: calcKostyl(24,36);
			}
			@include _576{
				font-size: cflm(12, 14);
				line-height: cflm(14,16);
				width: 90%;
			}
		}
		svg{
			fill: #fff;
			transform: rotate(180deg);
			transition: fill .3s ease;
			width: 25px;
			height: 13px;
			@include _1920{
				width: cfl(16, 25);
				height: cfl(11, 13);
			}
			@include _576{
				width: 12px;
				height: 12px;
			}
		}
	}
}

.js-open-other{
	&.opened{
		.js-hidden{
			display: block;
		}
	}
	.js-hidden{
		display: none;
	}
}


.open-other{
	cursor: pointer;
	margin: 20px 0 0;
	&:hover{
		span{
			border-color: transparent;
		}
	}
	span{
		font-size: 15px;
		line-height: 18px;
		border-bottom: 1px solid #999999;
		color: #999999;
		transition: border-color $tr-time;
	}
}

.lead-phone{
	svg{
		width: 15px;
		height: 15px;
		fill: $main-clr-hover;
		margin-right: 8px;
	}
	span{
		font-size: 15px;
		line-height: 18px;
		color: $text-clr;
	}
}

.breadcrumbs{
	padding: 15px 0 35px;
	@include _992{
		padding: 10px 0 15px;
	}
	&-item{
		font-size: 15px;
		line-height: 18px;
		color: #999999;
		@include _992{
			font-size: 12px;
			line-height: 16px;
		}
	}
	&-back{
		display: block;
		margin: 15px 0 0;
		padding: 10px 0;
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
			span{
				color: $main-clr-hover;
			}
		}
		svg{
			width: 16px;
			height: 10px;
			fill: $text-clr;
			margin-right: 5px;
			transition: fill $tr-time;
		}
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $text-clr;
			transition: color $tr-time;
		}
	}
}

.page-title{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin: 0 0 35px;
	@include _992{
		margin: 0 0 25px;
	}
	&--line{
		align-items: center;
		justify-content: space-between;
	}
	&--notify{
		flex-direction: column;
	}
	&__name{
		margin-right: 15px;
		span{
			font-weight: 700;
			font-size: 36px;
			line-height: 42px;
			color: $text-clr;
			@include _992{
				font-size: 28px;
				line-height: 34px;
			}
		}
	}
	&__link{
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: $text-clr;
		position: relative;
		transition: color $tr-time;
		&:hover{
			color: $main-clr-hover;
		}
		&::before{
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background-color: $main-clr-hover;
			position: absolute;
			border-radius: 100%;
			left: -15px;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__content{
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}
	&__notify{
		margin: 15px 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		@include _768{
			flex-direction: column;
    		align-items: flex-start;
		}
		&-text{
			font-size: 15px;
			line-height: 24px;
			color: #282828;
		}
		&-link{
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			text-decoration-line: underline;
			color: $main-clr-hover;			
		}
	}
}

.swiper{
	transition: opacity $tr-time;
	opacity: 1;
	&-init{
		opacity: 0;
	}
	&-nav{
		cursor: pointer;
		&--next{
			svg{
				transform: rotate(180deg);
			}
		}
		svg{
			width: 36px;
			height: 25px;
			fill: #999999;
			transition: fill $tr-time;
		}
		&:hover{
			svg{
				fill: $alt-clr;
			}
		}
	}
	&-pagination{
		z-index: 1;
		&-bullet{
			margin: 0 10px;
			width: 10px;
			height: 10px;
			background-color: #6A6A6A;
			opacity: 1;
			transition: background-color $tr-time;
			&-active{
				background-color: $main-clr;
			}
		}
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.main-categories{
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	column-gap: 21px;
	row-gap: 21px;
	@include _1920{
		column-gap: cfl(14,21);
		row-gap: cfl(14,21);
	}
	@include _992{
		grid-template-columns: repeat(6, 1fr);
	}
	@include _576{
		grid-template-columns: repeat(4, 1fr);
	}
	@include _420{
		grid-template-columns: repeat(2, 1fr);
	}
	&__wrapper{
		margin-top: 73px;
		border-top: 1.5px solid #4B4B4B;
		border-bottom: 1.5px solid #4B4B4B;
		padding: 35px 0;
		@include _1920{
			margin-top: cfl(48.6,73);
			padding: cfl(23.3,35);
		}
		@include _992{
			padding: 20px 5px;
		}
	}
	&__card{
		display: flex;
		align-items: center;
		height: 105px;
		text-align: center;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 22.5px;
		transition: all .3s ease;
		justify-content: center;
		cursor: pointer;
		position: relative;
		width: 201px;
		@include _1920{
			height: cfl(70,105);	
			border-radius: cfl(15, 22.5);
			width: cfl(140.6,201);
		}
		@include _992{
			height: 74px;
			width: 140px;
		}
		span{
			font-size: 21px;
			line-height: 27.3px;
			font-weight: 600;
			padding: 0 11px;
			z-index: 1;
			@include _1920{
				font-size: cfl(14,21);
				line-height: cfl(18.2,27.3);
				padding: 0 cfl(7.3,11);
			}
			@include _992{
				font-size: 14px;
				line-height: 18px;
			}
			
		}
		&:hover{
			&.main-categories__card::before{
				background-color: $main-clr;
				opacity: 1;
			}
		}
		&::before{
			content: '';
			position: absolute;
			background-color: #282828;
			opacity: .5;
			width: 100%;
			height: 100%;
			border-radius: 22.5px;
			transition: all .3s ease;
			@include _1920{
				border-radius: cfl(15, 22.5);
			}
		}
	}
}

.theme-counter{
    cursor: pointer;
    display: grid;
    grid-template-columns: 26px 1fr 26px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    &__minus,
    &__plus{
		user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
			width: 13px;
			height: 13px;
			fill: $text-clr;
			transition: fill $tr-time;
		}
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
    }
    &__field{
        border: none;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: $text-clr;
    }
}

.swal2-title{
	@include _576{
		padding: 10px 20px 0;
		font-size: 22px;
	}
}

.swal2-html-container{
	@include _576{
		font-size: 16px;
	}
}

.swal2-actions{
	@include _576{
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
		margin: 0;
		padding: 10px 15px 0;
		.btn--swal{
			width: 100%;
			margin: 0;
		}
	}
}

.swal2-icon{
	@include _576{
		width: 50px;
		height: 50px;
		.swal2-icon-content{
			font-size: 30px;
		}
	}
}

.form-success{
	display: none;
	flex-direction: column;
	align-items: center;
	&.from-bg{
		background-color: #272727;
		border-radius: 22.5px;
		padding: 75px 0 87px;
		@include _1920{
			border-radius: clf(15,22.5);
			padding: cfl(50,75) 0 cfl(58,87);
		}
		@include _992{
			border-radius: 15px;
			padding: 35px 0 35px;
		}
		@include _576{
			border-radius: cflm(10,15);
			padding: cflm(35,45) 0 cflm(35,45);
		}
	}
	&.show{
		display: flex;
	}
	&__title{
		text-transform: uppercase;
		font-size: 32px;
		line-height: 43.89px;
		text-align: center;
		margin-bottom: 15px;
		@include _1920{
			font-size: cfl(24,32);
			line-height: cfl(29.26, 43.89);
			margin-bottom: cfl(10,15);
		}
		@include _992{
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 8px;
		}
	}
	&__description{
		font-weight: 400;
		font-size: 21px;
		line-height: 27.3px;
		text-align: center;
		margin-bottom: 30px;

		@include _1920{
			font-size: cfl(14,21);
			line-height: cfl(19.2,27.3);
			margin-bottom: cfl(20,30);
		}
		@include _992{
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 18px;
		}
	}
	svg{
		width: 108px;
		height: 111px;
		fill: #fff;
		@include _1920{
			width: cfl(72,108);
			height: cfl(74,111);
		}
		@include _992{
			width: 60px;
			height: 60px;
		}
		@include _576{
			width: cflm(50,60);
			height: cflm(50,60);
		}
	}
}
.button-container{
	overflow: hidden;
	margin-top: 5%;
	max-width: 272px;
	width: 100%;
	height: 77px;
	border-radius: 50px;
	@include _1920{
		max-width: cfl(182, 272);
		height: cfl(51, 77);
	}
	@include _992{
		height: 45px;
		max-width: 160px;
	}
	@include _576{
		height: cflm(40,45);
		max-width: cflm(135, 160);
		margin-top: 10%;
	}
}
.js-button{
	display: flex;
	position: relative;
	transition: color 0.4s ease-in-out, height 0.4s ease-in-out;
	text-transform: uppercase;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    color: #fff;
	height: 100%;
	z-index: 3;
	@include _1920{
		font-size: cfl(16,21);
	}
	@include _992{
		font-size: 11px;
	}
	@include _576{
		font-size: cflm(10,13);
	}
	&:hover{
		background-color: transparent;
	}
	
	span {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-radius: 50%;
		background-color: $main-clr;
		transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
		transform: translate(-50%, -50%);
		z-index: -1;
		&.lite{
			background-color: #ff0000;

		}
	}
	
	&:hover {
		color: #fff;
		span{
			width: 210%;
			height: 450px;

		}
	}
	
	&:active {
		background-color: $main-clr;
	}
}
.gradiend-filter{
	position: relative;

	&::before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background: linear-gradient(90deg, rgba(0,0,0,.25),rgba(0,0,0,.5),rgba(0,0,0,.25));
	}
}

