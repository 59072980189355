.search-line{
    display: flex;
    align-items: center;
    background: transparent;
    width: 100%;
    height: 45px;
    position: relative;
    border: 1px solid transparent;
    border-radius: 27px;
    padding: 0 8px;
    @include _1920{
        height: cfl(40,45);
    }
    &:hover{
        .search-line__line{
            max-width: 220px;
        }
        border-color:#4F4F4F;
    }
    &__line{
        max-width: 0px;
        overflow: hidden;
        transition: all .3s ease;
        height: 100%;
    }
    input{
        flex: 1 0 auto;
        background: transparent;
        border: none;
        height: 100%;
        padding: 0 15px;
        font-size: 16px;
        line-height: 21px;
        color: #fff;
        &::placeholder{
            color: #ADACAC;
            font-size: 16px;
            line-height: 21px;
        }
    }
    button{
        border: none;
        width: 35px;
        height: 35px;
        position: relative;
        background-color: transparent;
        transition: all .3s ease;
        @include _1920{
            width: cfl(20,35);
            height: cfl(20,45);
        }
        svg{
			width: 30px;
            height: 28px;
			stroke: #fff;
            fill: transparent;
            transition: all .15s ease;
		}
        &:hover{
            svg{
                stroke: #EB1717;
            }
        }
    }
    &__result{
        display: none;
        width: 100%;
        position: absolute;
        top: calc(100% + 1px);
        background-color: #fff;
        padding: 30px 20px;
        &.opened{
            display: block;
        }
        &:empty{
            display: none !important;
        }
    }
    &__list{

    }
    &__title{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #282828;
        margin: 0 0 10px;
    }
    &__item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        span{
            font-size: 15px;
            line-height: 18px;
            color: #282828;
            transition: color $tr-time;
        }
        svg{
            height: 12px;
            width: 8px;
            fill: #999999;
        }
        &:hover{
            span{
                color: $main-clr-hover;
            }
        }
    }
    &__extra{
        border-top: 1px solid #D0D0D0;
        padding: 15px 0 0;
        margin: 30px 0 0;
    }
}