.header{
    background-color: rgba(33, 33, 33, 0.8);
    height: 107px;
    z-index: 1000;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    @include _1920{
        height: cfl(71, 107);
    }
    @include _576{
        height: cflm(35, 42);
    }
    &__container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all .3s ease;
    }
    &__left{
        display: flex; 
        align-items: center;
        gap: 28px;

        @include _1920{
            gap: cfl(21,28);
        }

        &__btn{
            cursor: pointer;
            svg{
                width: 39px;
                height: 39px;  
                //margin-right: 28px;
                fill: #fff;
                transition: all .3s ease;
                &:hover{
                    fill: #FF0000;
                }
                @include _1920{
                    width: cfl(27,39);
                    height: cfl(27,39);
                    //margin-right: cfl(21,28);
                }
            }
        }
        &__phone{
            font-size: 30px;
            font-weight: 500;
            @include _1920{
                font-size: cfl(20,30);
            }
            &:hover{
                color: #FF0000;
            }
        }
    }
    &__right{
        display: flex;
        align-items: center;
        //width: 450px;
        &__btn{
            margin-right: 25px;
            display: flex;
            align-items: center;
            @include _1920{
                margin-right: clf(20,25);
            }
            &:first-child{
                margin-left: auto;
                svg{
                    width: 39px;
                    height: 39px;
                    @include _1920{
                        width: cfl(26,39);
                        height: cfl(26,39);
                    } 
                }
            }
            svg{  
                width: 34px;
                height: 34px; 
                fill: #fff;
                transition: all .3s ease;
                @include _1920{
                    width: cfl(27, 34);
                    height: cfl(27, 34);
                }
            }
            span{
                span{
                    font-size: 21px;
                    line-height: 21px;
                    transition: color .3s ease;
                    display: flex;
                    align-items: center;
                    @include _1920{
                        font-size: cfl(14,21);
                        line-height: cfl(14,21);
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 5px;
                        height: 5px;
                        background: #FF0000;
                        border-radius: 50%;
                        margin: 0 3px;
                        transition: all .3s ease;
                    }
                    &::before{
                        content: '';
                        display: block;
                        width: 5px;
                        height: 5px;
                        background: #FF0000;
                        border-radius: 50%;
                        margin: 0 3px;
                        transition: all .3s ease;
                    }
                }
            }
            &:hover{
                span{
                    color: #FF0000;
                    &::after{
                        background-color: #fff;
                    }
                    &::before{
                        background-color: #fff;
                    }
                }
                svg{
                    fill: #FF0000;
                }
            }
        }
    }
    &__logo{

        height: 40px;
        max-width: 400px;

        @include _1920{
            height: cfl(36, 53);
        }
        @include _992{
            width: auto;
        }  
        &:hover{
            img{
                opacity: .7;
            }
        }
        img{
            transition: opacity .3s ease;
            height: 100%;
        }
    }
}
.menu-catalog{
    position: absolute;
    top: 0;
    display: flex;
    left: -100%;
    width: 100%;
    z-index: 9999;
    transition: all .3s ease-out;
    &__container{
        background-color: rgba(55,55,55,.8);
        width: 35%;
        min-width: 35%;
        height: 100vh;
        padding: 45px;
        @include _1920{
            padding: cfl(30, 45);
        }
        ul{ 
            display: flex;
            flex-direction: column;
            li{
                margin-bottom: 30px;
                @include _1920{
                    margin-bottom: cfl(20, 30);
                }
                &:first-child{
                    margin-bottom: 40px;
                    @include _1920{
                        margin-bottom: 40px;
                    }
                }
                &:hover{
                    svg{
                        fill: $main-clr;
                    }
                }
                svg{
                    height: 20px;
                    width: 20px;
                    fill: #fff;
                    transform: rotate(180deg);
                    transition: fill .3s ease;
                    @include _1920{
                        height: cfl(13.33, 20);
                        width: cfl(13.33, 20);
                    }
                }
                a{
                    font-size: 21px;
                    line-height: 32px;
                    text-transform: uppercase;
                    cursor: pointer;
                    &:hover{
                        color: $main-clr;
                    }
                    @include _1920{
                        font-size: cfl(14, 21);
                        line-height: cfl(21.33, 32);
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 18px; 
                    }
                }
                &.menu-item{
                    margin-bottom: 20px;
                    @include _1920{
                        margin-bottom: cfl(13.33, 20);
                    }
                    &:hover{
                        color: #fff;
                    }
                    a{
                        opacity: .6;
                        transition: all .3s ease;
                        font-size: 18px;
                        line-height: 27.45px;
                        &:hover{
                            color: $main-clr;
                            opacity: 1;
                        }
                        @include _1920{
                            font-size: cfl(12, 18);
                            line-height: cfl(18.30, 27.45); 
                        }
                    }
                }
            }
        }
    }
    &__bg{
        width: 100%;
    }
    &.active{
        transform: translateX(100%);
    }
}