.footer{
    background: #272727;
}
.footer-main{
    &__container{
        display: grid;
        grid-template-columns: 33% 33% 1fr;
        column-gap: 50px;
        margin: 68px 0;
        @include _1920{
            column-gap: cfl(33.3,50);
            margin: cfl(45.4, 68) 0;
        }
        @include _576{
            grid-template-columns: 1fr;
            row-gap: 15px;
        }
    }
    &__company{
        display: flex;
        flex-direction: column;
        padding-right: 60px;
        @include _1920{
            padding-right: cfl(40,60);
        }
        &__logo{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 14px;
            @include _1920{
                margin-bottom: cfl(9.3,14);
            }
            &:hover{
                span{
                    color: #f00;
                }
            }
            span{
                font-size: 19.5px;
                line-height: 25.5px;
                font-weight: 600;
                padding-right: 16px;
                transition: color .3s ease;
                @include _1920{
                    font-size: cfl(13, 19.5);
                    line-height: cfl(17, 25.5);
                    padding-right: cfl(10.6,16);
                }
                @include _576{
                    font-size: cflm(10,12);
                    line-height: cflm(12,14);
                }
            }
            img{
                width: 256px;
                @include _1920{
                    width: cfl(170.6, 256);
                }
                @include _576{
                    width: cflm(150, 200);
                }
            }
        }
        &__text{
            font-size: 18px;
            line-height: 25.2px;
            font-weight: 400;
            margin-top: 17px;
            @include _1920{
                font-size: cfl(12,18);
                line-height: cfl(16.8, 25.2);
                margin-top: cfl(11.3,17);
            }
            @include _992{
                font-size: 10px;
                line-height: 12px;
                margin-top: 10px;
            }
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        &__row{
            display: inline;
            &:not(:last-child){
                margin-bottom: 38px;
                @include _1920{
                    margin-bottom: cfl(25.3, 38);
                }
            }
            &__title{
                display: flex;
                width: 100%;
                font-size: 25.5px;
                font-weight: 600;
                line-height: 42px;
                margin-bottom: 10px;
                @include _1920{
                    font-size: cfl(17, 25.5);
                    line-height: cfl(28, 42);
                    margin-bottom: cfl(6.6,10);
                }
                @include _992{
                    font-size: 14px;
                    line-height: 21px;
                }
            }
            &__item{
                font-size: 21px;
                line-height: 52px;
                opacity: .5;
                white-space: nowrap;
                transition: opacity .3s ease;
                @include _1920{
                    font-size: cfl(14,21);
                    line-height: cfl(34.6, 52);
                }
                @include _992{
                    font-size: 10px;
                    line-height: 18px;
                }
                &:not(:last-child){
                    margin-right: 32px;
                    @include _1920{
                        margin-right: cfl(21.3, 32);   
                    }
                }
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    &__contact{
        display: flex;
        flex-direction: column;
        &__title{
            font-size: 25.5px;
            line-height: 42px;
            font-weight: 600;
            margin-bottom: 26px;
            @include _1920{
                font-size: cfl(16.8,25.5);
                line-height: cfl(28,42);
                margin-bottom: cfl(17.3, 26);
            }
            @include _576{
                font-size: cflm(14,16);
                line-height: cflm(16,18);
                margin-bottom: cflm(10,15);
            }
        }
        &__phone{
            font-size: 30px;
            line-height: 42px;
            font-weight: 600;
            margin-bottom: 14px;
            transition: all .3s ease;
            text-decoration: underline;
            @include _1920{
                font-size: cfl(20,30);
                line-height: cfl(28,42);
                margin-bottom: cfl(9.3, 14);
            }
            @include _576{
                font-size: cflm(12,14);
                line-height: cflm(14,16);
                margin-bottom: cflm(10,15);
            }
            &:hover{
                color: #f00;
            }
        }
        &__mail{
            font-size: 21px;
            line-height: 28.5px;
            font-weight: 400;
            margin-bottom: 30px;
            transition: all .3s ease;
            text-decoration: underline;
            @include _1920{
                font-size: cfl(14,21);
                line-height: cfl(19, 28.5);
                margin-bottom: cfl(20,30);
            }
            @include _576{
                font-size: cflm(12,14);
                line-height: cflm(14,16);
                margin-bottom: cflm(10,15);
            }
            &:hover{
                color: #f00;
            }
        }
        &__main{
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            @include _1920{
                margin-bottom: cfl(22,32);
            }
            @include _576{
                margin-bottom: cflm(10,15);
            }
            span{
                font-size: 21px;
                line-height: 28.5px;
                font-weight: 400;
                @include _1920{
                    font-size: cfl(14,21);
                    line-height: cfl(19, 28.5);
                }
                @include _576{
                    font-size: cflm(12,14);
                    line-height: cflm(14,16);
                margin-bottom: cflm(10,15);
                } 
                &:first-child{
                    margin-bottom: 13px;
                    @include _1920{
                        margin-bottom: cfl(8.6, 13);
                    }
                }
            }
        }
        &__social-network{
            display: flex;
            width: 90%;
            @include _576{
                width: 100%;
            }
            span{
                font-size: 25.5px;
                line-height: 42px;
                font-weight: 600;
                white-space: nowrap;
                margin-right: 32px;
                @include _1920{
                    display: flex;
                    align-items: center;
                    font-size: cfl(17, 25.5);
                    line-height: cfl(28,42);
                    margin-right: cfl(21,32);
                }
                @include _576{
                    font-size: cflm(14,16);
                    line-height: cflm(14,16);
                    margin-right: cflm(20,30);
                }
            }
            &__items{
                display: flex;
                justify-content: space-between;
                width: 100%;
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    background-color: #2E2E2E;
                    border-radius: 50%;
                    transition: all .3s ease;
                    @include _1920{
                        width: cfl(32,48);
                        height: cfl(32,48);
                    }
                    @include _576{
                        width: cflm(32,36);
                        height: cflm(32,36);
                    }
                    &:hover{
                        background-color: #f00;
                    }
                    svg{
                        width: 20px;
                        height: 20px;
                        fill: #fff;
                        @include _1920{
                            width: cfl(13.3,20);
                            height: cfl(13.3,20);
                        }
                        @include _576{
                            width: cflm(20,25);
                            height: cflm(20,25);
                        }
                    }
                    img{
                        width: 20px;
                        height: 20px;
                        @include _1920{
                            width: cfl(13.3,20);
                            height: cfl(13.3,20);
                        }
                        @include _576{
                            width: cflm(20,25);
                            height: cflm(20,25);
                        }
                    }
                }
            }
        }
    }
}
.footer-bottom{
    margin-top: 21px;
    font-weight: 400;
    font-size: 18px;
    line-height: 31.6px;
    opacity: .5;
    @include _1920{
        font-size: cfl(12,18);
        line-height: cfl(21,31.6);
        margin-top: cfl(14,21);
    }
    @include _992{
        font-size: 12px;
        line-height: 21px;
        margin-top: 14px;
    }
    @include _576{
        font-size: cflm(12,14);
        line-height: cflm(12,14);
        margin-top: cflm(5,10);
    }
    &__copyright{
    }
    &__privacy{
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}