.header-mobile{
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .header-logo{
        max-width: 150px;
        height: 25px;
        img{
            height: 100%;
            width: 100%;
        }
        @include _576{
            height: cflm(20, 25);
        }
    }
    .header-button{
        svg{
            fill: #fff;
            width: 25px;
            height: 25px;
        }
    }
    .header-button__count{
        font-size: 30px;
        &::after{
            content: '';
            width: 4px;
            height: 4px;
            margin-left: 3px;
            background-color: #c80000;
            border-radius: 50%;
        }
        &::before{
            content: '';
            width: 4px;
            height: 4px;
            margin-right: 3px;
            background-color: #c80000;
            border-radius: 50%;
        }
    }
}

.header-burger{
    position: relative;
    width: 20px;
    height: 20px;
    svg{
        width: 100%;
        height: 100%;
        fill: #fff;
    }
    span{
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $text-clr;
        transition: $tr-time;
        transition-property: top, bottom, opacity, transform;
        &:nth-child(1){
            top: 0;
        }
        &:nth-child(2){
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:nth-child(3){
            bottom: 0;
        }
    }
    &.opened{
        span{
            &:nth-child(1){
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
            }
        }
    }
}

.mobile-menu{
    position: fixed;
    z-index: 99;
    top: 55px;
    bottom: 0;
    left: 100%;
    width: 100%;
    //В мобильном меню при высокой длине показывается белый цвет
    //background-color: #fff;
    background-color: #373737;
    overflow: scroll;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) $tr-time;
    border-top: 1px solid #D0D0D0;
    &.opened{
        left: 0%;
    }

    .mobile-actions{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @include _576{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .header-button{
        flex-direction: row;
        padding: 20px;
        
        &:not(:last-child) {
            margin: 0;
        }
        svg{
            margin: 0 12px 0 0;
        }
        @include _1200{
            &:not(:last-child){
                border-right: 1px solid #D0D0D0;
            }
        }
        @include _576{
            &:not(:last-child){
                border-right: none;
            }
            &:nth-child(odd){
                border-right: 1px solid #D0D0D0;
            }
            &:nth-child(1),
            &:nth-child(2){
                border-bottom: 1px solid #D0D0D0;
            }
        }
    }

    .mobile-nav{
        &__catalog{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-color: $main-clr;
            padding: 18px 15px;
            span{
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #282828;
            }
            svg{
                height: 12px;
                width: 8px;
                fill: #282828;
            }
        }
        &__list{
            background-color: #373737;
            &--black{
                background-color: #282828;
                li{
                    a{
                        font-weight: 500 !important;
                        font-size: 15px !important;
                        line-height: 18px !important;
                        color: #FFFFFF !important;
                        opacity: .9;
                    }
                }
            }
            padding: 10px 15px;
            li{
                a{
                    display: inline-block;
                    padding: 10px 0;
                    font-size: 14px;
                    line-height: 16px;
                    color: #fff;

                }
            }
        }
    }

    .mobile-main{
        background-color: #373737;
        padding: 0 0 25px;
    }
    

    .mobile-search{
        background-color: #373737;
        padding: 0 15px;

        .search-line{
            width: 100%;
            border: 1px solid #4f4f4f;
            height: 45px;
            &:hover{
                border-color: transparent;
            }
            button{
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }
        }
    }
}