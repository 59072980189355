body{
	min-height: 100vh;
	margin: 0;
	color: $text-clr;
	font-family: 'Montserrat';
	font-weight: 500;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #373737;
	&.opened{
		overflow: hidden;
	}
	&.menu-opened{
		main{
			#page-container{
				filter: brightness(.2);
			}
			.header__container{
				filter: brightness(.2);
			}
			.menu-catalog{
				transform: translateX(100%);
			}
		}
	}
}
main{
	position: relative;
	flex: 1 0 auto;
}
p,ol,ul,h1,h2,h3,button,form{
	margin: 0;
	padding: 0;
}
button, input, textarea{
	font-family: inherit;
	&:focus{
		outline: none;
	}
}
input{
	min-width: 0;
	font-size: inherit;
	appearance: none;
}
ul, li{
	list-style-type: none;
}

input::-webkit-input-placeholder{
	font-size: 15px;
	line-height: 18px;
	color: #999999;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}
a{
	color: $text-clr;
	text-decoration: none;
	transition: color $tr-time;
	&:hover{
		color: var(--main);
	}
}
svg{
	vertical-align: middle;
	transition: fill $tr-time;
}
picture{
	display: block;
}
img{
	display: block;
}
source{
	display: none;
}
*, ::before, ::after{
	box-sizing: border-box;
}
:focus{
	outline: none;
}
#page-container{
	transition: all .3s ease;
}

.filter_map {
	&--gray {
		filter: grayscale(1);
		opacity: 0.9;
	}
}